<template>
  <vee-form
    @submit="createHoldOrder"
    :validation-schema="holdOrderSchema"
    v-slot="{ errors }"
    ref="hold_form"
  >
           <div
    class="modal fade"
    id="HoldOrderModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="DiscountModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="m-header mt-3">
          <h4 class="text-center" id="HoldOrderModal">Mettre en attente</h4>
        </div>
        <div class="m-body p-3">
            <div class="form-group">
              <vee-field
                name="name"
                placeholder="Saisir une note"
                :class="{ 'is-invalid': errors.name }"
                type="text"
                class="form-control"
              />
              <ErrorMessage class="text-danger" name="name" />
            </div>
        </div>
        <div class="m-footer text-right p-2">
            <button class="btn btn-danger mr-1" data-dismiss="modal">
              <i class="fas fa-times"></i> Annuler
            </button>
            <button class="btn btn-primary">
              <i class="fas fa-save"></i> Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
  </vee-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "HoldOrderModel",
  props: {
    selectedCustomer: {
      type: Object,
      required: true,
    },
    getApiData: {
      type: Object,
      required: true,
    },
    discount: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      holdOrderSchema: {
        name: "required|min:4",
      },
    };
  },
  computed: {
    ...mapState({
      order: (state) => state.cart.order,
    }),
  },
  methods: {
    ...mapActions(["addHoldOrder", "clearCart"]),
    createHoldOrder(values) {
      let hold = {
        customer: this.selectedCustomer,
        date: new Date(),
        lines: this.order.items,
        name: values.name,
        discount: this.discount,
      };
      this.addHoldOrder(hold);
      this.customerId = this.getApiData.settings.store.account_id;
      this.clearCart();
      this.$refs.hold_form.resetForm();
      $("#HoldOrderModal").modal("hide");
      this.$emit("resetDiscount");
      this.$toast.success("Vente mises en attente", {
        position: "top-right",
        timeout: 5000,
      });
    },
  },
};
</script>
