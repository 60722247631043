<template>
  <div
    class="modal fade"
    id="storeModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="StoreTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="m-header mt-2">
          <h4
            class="text-center font-weight-bold"
            style="padding: 1.5rem"
            id="StoreTitle"
          >
            Le stock par entrepôt
          </h4>
        </div>
        <div class="m-body p-3">

          <table class="table table-bordered table-hover table-responsive" v-if="warehouses.length">
            <thead class="">
              <tr>
                <th scope="col" style="width: 15rem">Entrepôt</th>
                <th scope="col" style="width: 15rem">Stock</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="o in warehouses" :key="o.id">
                <td>{{ o.title }}</td>
                <td>{{ o.quantity }}</td>
              </tr>
            </tbody>
          </table>
          <base-empty-result v-else />

        </div>
        <div class="m-footer mb-3">
          <div class="col-12">
            <button class="btn btn-danger float-right" data-dismiss="modal">
              <i class="fas fa-times"></i> Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    warehouses: {
      type: Array,
      required: true,
    },
  },
};
</script>
