<template>
  <div class="col-12 item-detail py-1 rounded">
    <div
      data-toggle="collapse"
      :href="'#collapse-item-' + item.id"
      role="button"
      aria-expanded="false"
      :aria-controls="'collapse-item-' + item.id"
      class="row justify-content-between bg-light"
    >
      <div class="col-8 text-left d-flex justify-content-start p-0">
        <div class="mx-1">
          <span
            data-toggle="collapse"
            :href="'#collapse-item-' + item.id"
            role="button"
            aria-expanded="false"
            :aria-controls="'collapse-item-' + item.id"
            class="btn btn-light btn-sm text-primary"
            ><i class="fa fa-sort-down"></i
          ></span>
        </div>
        <div>{{ item.name }}</div>
      </div>
      <div class="col-4 text-right d-flex justify-content-end p-0">
        <div class="mr-1">
          {{ numFormat(modifiedPriceIncltax) }} x {{ item.quantity }}
        </div>
        <div>
          <span
            @click.prevent="removeAllItemFromCart(item.id)"
            class="btn btn-light btn-circle btn-sm text-danger mx-1 border"
            ><i class="fas fa-trash"></i
          ></span>
        </div>
      </div>
    </div>
    <div
      :id="'collapse-item-' + item.id"
      class="collapse multi-collapse row justify-content-between bg-light pt-2"
    >
      <div class="col-9 text-left d-flex justify-content-start p-0">
        <div
          @click.prevent="removeItemFromCart(item.id)"
          class="ml-1 btn btn-light btn-circle btn-sm border text-warning"
        >
          <i class="fas fa-minus"></i>
        </div>
        <div
          @click.prevent="addNewItemToCart()"
          class="btn btn-light btn-circle btn-sm mx-2 border text-info"
        >
          <i class="fas fa-plus"></i>
        </div>
        <div class="d-inline" style="width: auto">
          <vee-form @submit="updateItemQuantity" class="d-inline">
            <vee-field
              placeholder="Quantity"
              id="quantity-update-input"
              type="number"
              v-model="quantity"
              name="quantity"
            />
            <button
              type="submit"
              class="btn btn-light text-success border btn-sm mb-2 ml-1"
            >
              <i class="fa fa-check"></i>
            </button>
          </vee-form>
        </div>
      </div>
      <div class="col-3 text-right d-flex justify-content-end">
        <div class="mr-3 text-primary font-weight-bold">
          {{ amount }}
        </div>
      </div>

      <div style="margin-left:40px">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input type="checkbox" v-model="applyAmountDiscount" aria-label="" @click="applyAmountDiscount = !applyAmountDiscount">
            </div>
          </div>
          <input
            type="number"
            v-model="discount"
            class="form-control"
            :placeholder="placeHolderMessage"
             style="width:120px"
          />
        <div class="input-group-append">
          <span class="input-group-text"> {{ applyAmountDiscount ? "$" : "%" }} </span>
        </div>
      </div>
     </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Toggle from "@vueform/toggle";
import { mapState } from "vuex";
import cartItemsMixin from "@/includes/cartItemsMixin";
export default {
  props: ["item"],
  mixins: [cartItemsMixin],
  components: {
    Toggle,
  },
  data() {
    return {
      quantity: this.item.quantity,
      discount: null,
      applyAmountDiscount: true,
      modifiedPriceIncltax: null,
      sale_oos_item: 0,
    };
  },

  created() {
    let { settings } = this.getApiData;
    this.sale_oos_item = settings.store.sale_oos_item;
  },
  mounted() {
    this.discount = this.priceIncTax * this.item.discount;
    if (this.discount) {
      this.modifiedPriceIncltax = this.priceIncTax - this.discount;
    } else {
      this.modifiedPriceIncltax = this.priceIncTax;
    }
  },
  methods: {
    ...mapActions([
      "addItemToCart",
      "updateCartItemQuantity",
      "removeItemFromCart",
      "removeAllItemFromCart",
    ]),
    ...mapMutations(["updateOrderItemDiscount"]),
    updateItemQuantity() {
      // allow to add items doesn't exist on stock to shopping cart.
      if(this.sale_oos_item) {
        this.item.quantity = Number(this.quantity);
        this.updateCartItemQuantity(this.item);
        let el = document.getElementById("collapse-item-" + this.item.id);
        if (el.classList.contains("show")) {
          el.classList.remove("show");
        }
        return;
      }

      let match = _.find(this.getApiData.items, (o) => o.id === this.item.id);
      if (match.stock < this.quantity) {
        this.$toast.error(
          `${match.stock} articles pour ce produit.`,
          {
            position: "top-right",
            timeout: 5000,
          }
        );
      } else {
        this.item.quantity = Number(this.quantity);
        this.updateCartItemQuantity(this.item);
        let el = document.getElementById("collapse-item-" + this.item.id);
        if (el.classList.contains("show")) {
          el.classList.remove("show");
        }
      }
    },
    addNewItemToCart() {
      // allow to add items doesn't exist on stock to shopping cart.
      if(this.sale_oos_item) {
        this.addItemToCart(this.item);
        return;
      }

      if (this.item.stock < this.item.quantity + 1) {
        this.$toast.error(
          `${this.item.stock} articles pour ce produit.`,
          {
            position: "top-right",
            timeout: 5000,
          }
        );
        return;
      }
      this.addItemToCart(this.item);
    },
    numFormat(number) {
      return this.$filters.numFormat(number);
    },
    applyDiscount() {
      let price = 0;
      let percentage = 0;
      if (!this.applyAmountDiscount) {
        if (this.discount > 100) {
          this.$toast.error(
            `La remise appliquée est supérieure à 100% `,
            {
              position: "top-right",
              timeout: 5000,
            }
          );
          return;
        }
        price = this.priceIncTax - (this.discount * this.priceIncTax) / 100;
        percentage = this.discount;
      } else {
        if (this.discount > this.item.price) {
          this.$toast.error(
            `La valeur saisie est supérieur au prix de l'article: ${this.discount} / ${this.item.price}.`,
            {
              position: "top-right",
              timeout: 5000,
            }
          );
          return;
        }
        percentage = Number(this.discount / this.priceIncTax);
        price = this.priceIncTax - this.discount;
      }
      this.modifiedPriceIncltax = price;
      this.updateOrderDiscount(percentage);
    },

    updateOrderDiscount(percentage) {
      let updatedItem = this.items.map((o) => {
        return o.id === this.item.id ? { ...o, discount: percentage } : o;
      });
      this.updateOrderItemDiscount(updatedItem);
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.cart.order.items,
    }),
    ...mapGetters(["getApiData"]),
    amount() {
      return this.$filters.numFormat(this.modifiedPriceIncltax * this.quantity);
    },
    priceIncTax() {
      return this.fixNumber((this.item.price * (100 + this.item.tax)) / 100);
    },
    placeHolderMessage() {
      if (this.applyAmountDiscount) {
        return "Montant";
      } else {
        return "Pourcentage";
      }
    },
  },
  watch: {
    item(newValue) {
      this.quantity = newValue.quantity;
    },
    discount(newValue) {
      if (newValue > 0) {
        this.applyDiscount();
      } else {
        this.modifiedPriceIncltax = this.priceIncTax;
        this.updateOrderDiscount(0);
      }
    },
    applyAmountDiscount() {
      this.applyDiscount();
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
input {
  height: inherit;
}
.item-detail {
  max-height: auto;
}
#quantity-update-input {
  text-align: center;
  max-width: 100px;
  height: 31px;
  border: none;
}
.custom-input {
  margin-left: 4.7rem;
  width: 8.7rem;
  margin-top: -2rem;
  max-width: 19rem;
  margin-right: 13rem;
  margin-bottom: 0.5rem;
  height: fit-content;
}
</style>
