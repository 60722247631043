<template>
  <div class="container">
    <div class="d-flex flex-row bd-highlight">
      <div class="py-2 bd-highlight">
        <base-search
          @search-event="searchCollback"
          @reset="allItems"
          :placeholder="'Réf, Désig, Code à barre'"
        />
      </div>
      <div class="py-2 bd-highlight ml-2">
        <base-remove-filter-button
          :rendered="showFilterButton || filterdItems.length != items.length"
          :showIcon="false"
          customClass="float-left btn btn-default bg-light text-primary border"
          :all="all"
        />
      </div>
      <div class="p-2 bd-highlight">
        <button
          class="float-left btn btn-default bg-light text-primary border ml-2"
          @click="refetchDataFromApi"
        >
          <i class="fas fa-sync-alt" title="Actualiser les articles"></i>
        </button>
      </div>
      <div class="ml-auto pt-2">
        <p class="text-muted">{{ filterdItems.length }} Articles</p>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-sm-12">
        <base-empty-result v-if="!itemsAvailable" class="mt-3" />
        <div class="row mt-3" v-else>
          <AppPosItem v-for="item in filterdItems" :key="item.id" :item="item" :sale_oos_item="sale_oos_item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppPosItem from "@/views/pos/PosItem";

export default {
  name: "items",
  components: {
    AppPosItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    refetchDataFromApi: {
      type: Function,
      required: true,
    },
    allItems: {
      type: Function,
      required: true,
    },
    showFilterButton: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      filterdItems: this.items,
      sale_oos_item: 0,
    };
  },
  created() {
    let { settings } = this.apiData;
    this.sale_oos_item = settings.store.sale_oos_item;
  },  
  methods: {
    ...mapActions(["addItemToCart"]),
    searchCollback(keyword) {
      let resByName = _.filter(this.items, (item) =>
        _.includes(item.name, keyword.toLowerCase())
      );
      let resByReference = _.filter(this.items, (item) =>
        _.includes(item.reference, keyword)
      );
      if (keyword !== null && keyword !== "" && keyword.length > 0) {
        let match = _.find(this.items, (o) => o.barcode === keyword);
        if (match) {
          for (let key in match) {
            if (match.hasOwnProperty(key)) {
              match.quantity = 1;
              this.addItemToCart(match);
              this.allItems();
              return;
            }
          }
        }
      }
      this.filterdItems = _.unionBy(resByName, resByReference, "id");
    },

    all() {
      this.filterdItems = this.items;
      this.allItems();
    },
  },
  computed: {
    ...mapGetters({
      apiData: "getApiData",
    }),
    itemsAvailable() {
      return typeof this.filterdItems !== "undefined" && this.filterdItems.length;
    },
  },
  watch: {
    items(newValue) {
      this.filterdItems = newValue;
    },
  },
};
</script>
