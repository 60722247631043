<template>
  <div v-if="!categoriesAvailable" class="alert alert-warning mx-4">
    <p>Aucune catégorie à afficher !!</p>
  </div>
  <div class="container" v-else>
    <div class="row no-gutters">
      <div
        class="col-12 col-lg-6 col-xl-6 mb-2"
        @click.prevent="handleEvent(category.id)"
        v-for="category in categories"
        :key="category.id"
      >
        <div class="card card-body bg-white shadow-sm mr-1">
          <div class="d-flex justify-content-between">
            <div class="font-weight-bold">
              {{ category.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default defineComponent({
  name: "Categories",
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    handleEvent(id) {
      this.$emit("category-event", id);
    },
  },
  computed: {
    categoriesAvailable() {
      return typeof this.categories !== "undefined" && this.categories.length;
    },
  },
});
</script>

<style scoped>
.card:hover {
  background: #f7f7f7;
  border: 1px solid rgb(2, 117, 216);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  cursor: pointer;
  transform: scale(.97);
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: yellow;
}
.category__cursor {
  cursor: pointer;
}
</style>
