<template>
  <base-loading v-if="isLoading" />
  <div v-else>
    <div class="row">
      <div class="col-lg-6 col-md-7 col-sm-12 col-xl-8 mt-3">
        <div class="col-md-12">
          <base-breadcrumb :pageName="currentPageName" :storeName="storeName" />
        </div>
        
        <app-tabs :tabList="tabList">
          <template v-slot:tabPanel-1>
            <app-categories :categories="categories" @category-event="categoryCollback" />
          </template>

          <template v-slot:tabPanel-2>
            <app-items
              :showFilterButton="filterdItems.length != originalItems.length"
              :items="items"
              :refetchDataFromApi="refetchDataFromApi"
              :allItems="allItems"
            />
          </template>

          <template v-slot:tabPanel-3>
            <app-items
              :showFilterButton="filterdItems.length != originalItems.length"
              :items="inactiveItems"
              :refetchDataFromApi="refetchDataFromApi"
              :allItems="allItems"
            />
          </template>
        </app-tabs>
      </div>
      <div class="col-lg-6 col-md-5 col-xl-4 col-sm-12 bg-white p-1">
        <div class="pos-cart">
          <AppPosCart :customersData="customers" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import Paginate from "vuejs-paginate-next";
import AppItems from "@/views/pos/Items";
import AppPosCart from "@/views/pos/cart/Cart";
import AppCategories from "@/views/pos/Categories.vue";
import AppTabs from "@/components/Tabs";

export default {
  components: {
    AppItems,
    AppPosCart,
    Paginate,
    AppCategories,
    AppTabs,
  },
  data() {
    return {
      originalItems: [],
      filterdItems: [],
      page: 1,
      customers: [],
      categories: [],
      currentPageName: "Nouvelle vente",
      storeName: null,
      isLoading: false,
      tabList: ["Catégories", "Articles disponibles", "Ruptures de stock"],
    };
  },
  created() {
    this.isLoading = true;
    this.getApiData().then((res) => {
      this.storeName = res.settings.store.title;
      this.customers = res.customers;
      this.categories = res.categories.filter(o => o.status === 'active');
      this.originalItems = res.items;
      this.filterdItems = res.items;
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions(["fetchDataFromApi", "getApiData", "addItemToCart"]),
    ...mapMutations(["updateActiveTab"]),
    refetchDataFromApi() {
      this.isLoading = true;
      this.fetchDataFromApi().then((res) => {
        let { customers, categories, items } = res;
        this.customers = customers;
        this.categories = categories;
        this.originalItems = items;
        this.filterdItems = items;
        this.isLoading = false;
      });
    },
    categoryCollback(id) {
      this.updateActiveTab(2);
      this.filterdItems = _.filter(this.originalItems, (item) => item.categoryid === id);
    },
    allItems() {
      window.dispatchEvent(new CustomEvent("clear-search-input"));
      this.filterdItems = this.originalItems;
    },
  },
  computed: {
    items() {
      return (
        _.filter(this.filterdItems, function (o) {
          return o.stock > 0;
        }) || []
      );
    },
    inactiveItems() {
      return (
        _.filter(this.filterdItems, function (o) {
          return o.stock <= 0;
        }) || []
      );
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  overflow-y: scroll;
  position: relative;
  height: 10.5rem;
}
.products-wrapper {
  overflow-y: auto;
  position: relative;
  height: 30.5rem;
}
.modal {
  color: rgb(5, 5, 5);
  h5 {
    margin-left: auto;
    margin-top: 0.5rem;
  }
  .form-control:focus {
    box-shadow: none;
  }
  .m-footer,
  .m-body {
    margin: 1rem;
  }
  p {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  button {
    padding: 0.5rem;
  }
  input {
    height: 3.4rem;
  }
}
.icon-card {
  background-color: var(--pos-secondary-color);
  font-size: 2.1rem;
  i {
    border-radius: 6px;
    box-shadow: 0 0 3px 0 rgb(0, 0, 0.15);
    color: var(--pos-font-color);
    cursor: pointer;
    padding: 8px;
    margin-right: 0.5rem;
    position: relative;
    width: max-content;
  }
}
.pos-showcase {
  overflow-y: scroll;
  height: 400px;
  position: relative;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: yellow !important;
  .slide-container button {
  }
  background-color: darkcyan !important;
}
.pos-cart {
  padding: 5px;
}
</style>
