<template>
  <div class="col-xl-3 col-6 col-md-6 mb-4">
    <div :class="`card border-left-${customeClass} shadow h-100 py-2`">
      <div class="card-body">
        <div class="row no-gutters align-items-center">
          <div class="col mr-2">
            <div
              :class="`text-xs font-weight-bold text-${customeClass} text-uppercase mb-1`"
            >
              {{ title }}
            </div>
            <div class="h5 mb-0 font-weight-bold text-gray-800">
              <div>
                {{ $filters.numFormat(total) }}
              </div>
              <div v-if="!total && hasElse">0,00</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalCard",
  props: {
    total: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    customeClass: {
      type: String,
      default: "primary",
    },
    hasElse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
