<template>
  <div
    class="modal fade p-3"
    id="DiscountModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="DiscountModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="m-header mt-2 d-flex justify-content-between">
          <h4 class="p-3" id="DiscountModelTitle">
            Appliquer une remise {{ discountLable }}
          </h4>
          <Toggle v-model="discountOnTotalIncltax" class="mt-2 p-3" />
        </div>
        <div class="d-flex justify-content-between p-3">
          <div>
            <button
              @click.prevent="discount.type = 'percentage'"
              :class="{ 'btn-info': discount.type === 'percentage' }"
              class="btn btn-default"
            >
              Pourcentage
            </button>
            <button
              v-if="user_has_access_to_apply_discount"
              class="ml-2 btn btn-default"
              :class="{ 'btn-info': discount.type === 'amount' }"
              @click.prevent="discount.type = 'amount'"
            >
              Montant
            </button>
          </div>
        </div>
        <div class="m-body p-3">
          <div  v-show="discount.type === 'percentage'">
            <div
              class="d-flex justify-content-center"
              v-show="discount.type === 'percentage'"
            >
              <button
                class="btn btn-success btn-sm py-3 px-2 mr-2 w-100"
                @click="applyDiscount(5)"
                :class="{ activePercentage: percentage == 5 }"
              >
                5 &#37;
              </button>
              <button
                class="btn btn-success btn-sm py-3 px-2 mr-2 w-100"
                @click="applyDiscount(10)"
                :class="{ activePercentage: percentage == 10 }"
              >
                10 &#37;
              </button>
              <button
                class="btn btn-success btn-sm py-3 px-2 mr-2 w-100"
                @click="applyDiscount(15)"
                :class="{ activePercentage: percentage == 15 }"
              >
                15 &#37;
              </button>
              <button
                class="btn btn-success btn-sm py-3 px-2 w-100"
                @click="applyDiscount(20)"
                :class="{ activePercentage: percentage == 20 }"
              >
                20 &#37;
              </button>
            </div>
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-danger mt-3"
                data-dismiss="modal"
              >
                <i class="fas fa-times"></i> Annuler
              </button>
            </div>
          </div>

          <vee-form
            v-show="discount.type === 'amount'"
            v-slot="{ errors }"
            @submit="applyFixedDiscount"
            :validation-schema="fixedSchema"
            ref="fixed_form"
          >
            <div class="form-group">
              <vee-field
                name="amount"
                :class="{ 'is-invalid': errors.amount }"
                placeholder="Montant de la remise"
                type="number"
                class="form-control"
              />
              <ErrorMessage class="text-danger" name="amount" />
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn btn-danger mr-1" data-dismiss="modal">
                <i class="fas fa-times"></i> Annuler
              </button>
              <button type="submit" class="btn btn-success">
                <i class="fas fa-save"></i> Appliquer
              </button>
            </div>
          </vee-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from "@vueform/toggle";

export default {
  name: "DiscountModel",
  props: {
    discountLable: {
      type: String,
      required: true,
    },
    percentage: {
      type: Number,
      required: true
    },
    user_has_access_to_apply_discount: {
      type: Boolean,
      required: true,
    },
    fixedSchema: {
      type: Object,
      required: true,
    },
  },
  components: {
    Toggle,
  },
  data() {
    return {
      discountOnTotalIncltax: true,
      discount: {
        type: "percentage",
        value: 0,
        amount: 0,
        percentage: 0,
      },
    };
  },
  methods: {
    applyDiscount(value) {
      this.discount.percentage = value;
      let discount = {
        type: "percentage",
        value: value,
      };
      this.$emit("applyDiscount", discount);
    },
    applyFixedDiscount(values) {
      this.$emit("applyFixedDiscount", values);
    },
  },
  watch: {
    discountOnTotalIncltax(newValue) {
      this.$emit("toggleApplyDiscountOnTotalIncltax", newValue);
    },
  },
};
</script>

<style scoped>
.activePercentage {
  background-color: #83c68b;
}
</style>
