<template>
  <div class="cart-header mr-2">
    <h4 class="py-2">
      Nouvelle vente
      <span
        class="float-right btn btn-light border btn-circle"
        @click.prevent="clearCartItems"
        ><i class="fas fa-redo" title="Vider la panier"></i
      ></span>
    </h4>
  </div>
  <div class="cart-customer mb-3 mr-2">
    <div class="row" v-if="customers.length">
      <div class="col-10">
        <vee-select
          class="bg-light"
          v-model="this.customerId"
          :options="customers"
          label="name"
          :reduce="(customer) => customer.id"
        />
      </div>
      <div class="col-2">
        <button @click="showModal" class="float-right btn btn-light border btn-circle">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div v-else>
      <p class="text-center">Aucun client trouvé</p>
    </div>
  </div>
  <div class="card-items-container px-1" v-if="order.items && order.items.length">
    <div class="row cards no-gutters">
      <div class="col-12">
        <AppCartItem
          v-for="cartItem in order.items"
          :key="cartItem.id"
          :item="cartItem"
        />
      </div>
    </div>
  </div>
  <div class="alert alert-warning" role="alert" v-else>Aucun article sélectionné</div>
  <div class="my-3 mr-2">
    <div class="order">
      <div class="border-bottom" v-if="discount.value">
        Sous Total
        <span class="float-right">{{ $filters.numFormat(total.subTotal) }}</span>
      </div>
      <div class="border-bottom" v-if="discount.value && discount.value > 0">
        Remise ({{ $filters.numFormat(discount.percentage) }}&#37;)
        <span class="float-right">
          {{ $filters.numFormat(discount.amount) }}
        </span>
      </div>
      <div class="border-bottom border-top">
        Total HT
        <span class="float-right">{{ $filters.numFormat(total.totalExcltax) }} </span>
      </div>
      <div class="border-bottom">
        Taxe
        <span class="float-right">{{ $filters.numFormat(total.tax) }}</span>
      </div>
    </div>
    <div class="dis-hold-actions d-flex justify-content-between py-3">
      <button
        :disabled="total.totalIncltax == 0"
        v-if="!discount.value"
        type="submit"
        data-toggle="modal"
        data-target="#DiscountModal"
        class="btn btn-light btn-sm border text-info"
      >
        Réduction <i class="fas fa-percent"></i>
      </button>
      <div class="btn-group" role="group" aria-label="Basic example" v-else>
        <button
          class="btn btn-light btn-sm border text-danger"
          @click="applyDiscount({ type: 'percentage', value: 0 })"
        >
          Annuler <i class="fas fa-percent"></i>
        </button>
        <button
          data-toggle="modal"
          data-target="#DiscountModal"
          class="btn btn-light btn-sm border text-info"
        >
          Éditer <i class="fas fa-percent"></i>
        </button>
      </div>

      <button
        data-toggle="modal"
        data-target="#HoldOrderModal"
        class="btn btn-light btn-sm border text-warning"
      >
        En attente <i class="fas fa-pause"></i>
      </button>
    </div>
    <button
      class="btn btn-success btn-block text-white d-flex justify-content-between rounded"
      :disabled="total.totalIncltax == 0"
      @click.prevent="goToCheckout"
    >
      <div>
        <div>Paiement</div>
        <div>{{ total.items }} articles</div>
      </div>
      <div>
        <div class="mt-auto to-pay">
          {{ $filters.numFormat(total.totalIncltax) }} {{ currency }}
          <i class="fas fa-angle-double-right"></i>
        </div>
      </div>
    </button>
  </div>
  <!-- Discount Modal -->
  <AppCartDiscount
    @apply-discount="applyDiscount"
    @apply-fixed-discount="applyFixedDiscount"
    @toggle-apply-discount-on-total-incltax="toggleDiscount"
    :discountLable="discountLable"
    :user_has_access_to_apply_discount="user_has_access_to_apply_discount"
    :fixedSchema="fixedSchema"
    :percentage="discount.percentage"
  />
  <!-- Hold Order Model -->
  <AppCartHoldOrder
    @reset-discount="resetDiscount"
    :getApiData="getApiData"
    :selectedCustomer="selectedCustomer"
    :discount="discount"
  />

  <base-modal-bootstrap ref="bootstrapModal" title="Ajouter un nouveau client">
    <template v-slot:content>
      <AddCustomer @customer-id="setCustomerId"> </AddCustomer>
    </template>
  </base-modal-bootstrap>
</template>

<script>
import "vue-next-select/dist/index.min.css";
import "vue-select/dist/vue-select.css";
import "@vueform/toggle/themes/default.css";
import { mapState, mapGetters, mapActions } from "vuex";
import AddCustomer from "@/views/customer/AddCustomer";
import AppCartItem from "@/views/pos/cart/CartItem";
import AppCartDiscount from "@/views/pos/cart/CartDiscount";
import AppCartHoldOrder from "@/views/pos/cart/CartHoldOrder";
import cartItemsMixin from "@/includes/cartItemsMixin";
import CustomerService from "@/services/customer.service";
const customerService = new CustomerService();

export default {
  mixins: [cartItemsMixin],
  props: ["customersData"],
  data() {
    return {
      discount: {
        type: "percentage",
        value: 0,
        amount: 0,
        percentage: 0,
      },
      customers: null,
      customerId: null,
      allCustomers: null,
      currency: null,
      user_has_access_to_apply_discount: false,
      discountOnTotalIncltax: true,
      fixedDiscoutValue: 0,
      total: {
        subTotal: 0,
        tax: 0,
        totalExcltax: 0,
        totalIncltax: 0,
        items: 0,
      },
    };
  },
  components: {
    AppCartItem,
    AddCustomer,
    AppCartDiscount,
    AppCartHoldOrder,
  },
  created() {
    this.customers = this.customersData;
    if (this.order.customer && this.order.customer.hasOwnProperty("id")) {
      this.customerId = this.order.customer.id;
    } else {
      this.customerId = this.getApiData.settings.store.account_id;
    }
    this.currency = this.getApiData.settings.organization.currency;
    this.user_has_access_to_apply_discount = this.getApiData.settings.permissions[
      "pos.receipt.discount"
    ];
    if (this.order.items.length) {
      this.discount = this.order.discount;
      this.calculateTotal();
    }
  },
  methods: {
    ...mapActions(["updateOrder", "clearCart", "updateCustomersApiData"]),
    toggleDiscount(value) {
      this.discountOnTotalIncltax = value;
    },
    async setCustomerId(id) {
      this.isLoading = true;
      try {
        const customers = await customerService.get();
        this.customers = customers.data;
        this.updateCustomersApiData(this.customers);
      } catch (error) {
        console.log(error);
      }
      this.customerId = id;
      this.order.customer = { id: this.customerId };
      this.isLoading = false;
    },
    showModal() {
      this.$refs.bootstrapModal.showModal();
    },
    closeModal() {
      this.$refs.bootstrapModal.closeModal();
    },
    goToCheckout() {
      (this.order.customer = this.selectedCustomer),
        (this.order.discount = this.discount),
        (this.order.total = {
          subtotal: this.total.subtotal,
          incltax: this.total.totalIncltax,
          excltax: this.total.totalExcltax,
          items: this.total.items,
          tax: this.total.tax,
        });
        
      this.updateOrder(this.order);
      this.customerId = this.getApiData.settings.store.account_id;
      this.$router.push({ name: "Checkout" });
      return;
    },
    clearCartItems() {
      this.clearCart();
      this.customerId = this.getApiData.settings.store.account_id;
      this.resetDiscount();
      this.$toast.warning("Le panier est vide", {
        position: "top-right",
        timeout: 5000,
      });
    },
    resetDiscount() {
      this.discount = {
        type: "percentage",
        value: 0,
        amount: 0,
        percentage: 0,
      };
    },
    calculateTotal() {
      let subTotal = (lineTotal = 0);
      let tax = 0;
      let items = 0;
      let totalExcltax = 0;
      let totalIncltax = 0;
      let totalTax = 0;
      let lineTotal = 0;
      if (this.discountOnTotalIncltax) {
        _.each(this.order.items, (item) => {
          if (item.discount >= 1) {
            item.discount = item.discount / 100;
          }
          items += item.quantity;
          lineTotal = item.price * (1 - (item.discount || 0)) * item.quantity;
          subTotal += lineTotal;
          tax += (lineTotal * item.tax) / 100;
        });
        let OritotalIncltax = tax + subTotal;
        if (this.discount.type === "percentage") {
          this.discount.percentage = this.discount.value;
          this.discount.amount = (this.discount.percentage / 100) * OritotalIncltax;
          totalExcltax = subTotal - (subTotal * this.discount.percentage) / 100 || 0;
          totalTax = tax - (tax * this.discount.percentage) / 100 || 0;
          totalIncltax = this.fixNumber(totalTax + totalExcltax) || 0;
        } else {
          this.discount.percentage = (this.discount.value / OritotalIncltax) * 100;
          totalExcltax = subTotal - (subTotal * this.discount.percentage) / 100;
          totalTax = tax - (tax * this.discount.percentage) / 100;
          totalIncltax = this.fixNumber(totalTax + totalExcltax);
        }

        this.total = {
          subTotal,
          tax: totalTax,
          totalExcltax,
          totalIncltax,
          items,
        };
      } else {
        _.each(this.order.items, (item) => {
          subTotal += item.price * (1 - (item.discount || 0)) * item.quantity;
        });

        if (this.discount.type === "percentage") {
          this.discount.amount = (subTotal * this.discount.percentage) / 100;
        } else {
          this.discount.percentage = (this.discount.amount / subTotal) * 100;
        }

        _.each(this.order.items, (item) => {
          if (item.discount > 1) {
            item.discount = item.discount / 100;
          }
          items += item.quantity;
          tax +=
            (item.price *
              (1 - (item.discount || 0)) *
              (100 - this.discount.percentage) *
              item.quantity *
              item.tax) /
            100 /
            100;
        });

        totalExcltax = subTotal - this.discount.amount;
        totalIncltax = totalExcltax + tax;
        this.total = {
          subTotal,
          tax,
          totalExcltax,
          totalIncltax,
          items,
        };
      }
    },
    applyDiscount(discount) {
      let { type, value } = discount;
      if (this.discountOnTotalIncltax) {
        this.applyDiscountOnTotalIncltax(type, value);
      } else {
        this.applyDiscountOnTotalExcltax(type, value);
      }
      $("#DiscountModal").modal("hide");
    },
    applyDiscountOnTotalIncltax(type, value) {
      if (type == "percentage") {
        this.discount.type = "percentage";
        this.discount.value = value;
        this.discount.percentage = value;
      } else {
        this.discount.type = "amount";
        this.discount.value = value;
        this.discount.amount = value;
      }
      this.calculateTotal();
    },
    applyDiscountOnTotalExcltax(type, value) {
      if (type == "percentage") {
        this.discount.type = "percentage";
        this.discount.value = value;
        this.discount.percentage = value;
        this.discount.amount = (this.total.subTotal * value) / 100;
      } else {
        this.discount.type = "amount";
        this.discount.value = value;
        this.discount.amount = value;
        this.discount.percentage = (value / this.total.subTotal) * 100;
      }
      this.calculateTotal();
    },
    applyFixedDiscount(values) {
      this.discount.value = values.fixed;
      this.discount.amount = values.fixed;
      let discount = {
        type: "amount",
        value: values.amount,
      };
      this.applyDiscount(discount);
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.cart.order,
    }),
    ...mapGetters(["getApiData"]),
    fixedSchema() {
      return {
        amount: `required|min_value:0|max_value:${this.total.subTotal}`,
      };
    },
    discountLable() {
      return this.discountOnTotalIncltax ? "TTC" : "HT";
    },
    selectedCustomer() {
      let customer = _.find(this.customers, (o) => o.id === this.customerId);
      return customer;
    },
  },
  watch: {
    cartItems() {
      if (this.order.items.length == 0) {
        this.discount = {
          type: "percentage",
          percentage: 0,
          amount: 0,
          value: 0,
        };
      }
    },
    order: {
      handler(newValue) {
        this.calculateTotal();
        if (newValue.customer === null) {
          this.customerId = this.getApiData.settings.store.account_id;
        }
      },
      deep: true,
      immediate: true,
    },
    customerId(newValue) {
      this.order.customer = { id: newValue };
    },
  },
  unmounted() {
    this.customerId = this.getApiData.settings.store.account_id;
  },
};
</script>

<style scoped lang="scss">
.cart-item {
  background: white;
  hr {
    font-size: 2rem;
    color: black;
  }
  .d-flex {
    span {
      margin-right: 6%;
      font-size: 2rem;
      margin-top: -5rem;
      cursor: pointer;
    }
  }
  a {
    text-decoration: none;
    color: black;
  }
}

.card-items-container {
  height: 300px;
  overflow-y: scroll;
}

.cards {
  max-height: 50%;
}
.modal {
  color: rgb(5, 5, 5);
  h5 {
    margin-left: auto;
    margin-top: 0.5rem;
  }
  .form-control:focus {
    box-shadow: none;
  }
  .m-footer {
    margin: 1rem;
    button {
      margin-top: -4.5rem;
    }
  }
  .m-body {
    margin: 1rem;
  }
  p {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  button {
    padding: 0.5rem;
  }
  input {
    height: 3.4rem;
  }
  .wrapper {
    height: 300px;
  }
}
.to-pay {
  font-size: 2rem;
}
</style>
