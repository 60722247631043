<template>
  <div class="col-12 col-lg-6 col-xl-6 mb-2">
    <div class="card card-body bg-white shadow-sm" @click.prevent="addNewItemToCart">
      <!-- :class="{ 'no-item ': !itemExistOnStock }" -->
      <div class="d-flex justify-content-between">
        <div class="font-weight-bold">
          {{ item.name }} (<span class="text-success">{{ item.stock }}</span
          >)
        </div>
        <div class="price ml-2">
          {{ $filters.numFormat((item.price * (100 + item.tax)) / 100, "") }}
        </div>
      </div>
    </div>
  </div>
  <PosItemStock :warehouses="warehouses" />
</template>

<script>
import { mapActions, mapState } from "vuex";
import PosItemStock from "@/views/pos/PosItemStock";
import CatalogService from "@/services/catalog.service";
const catalogService = new CatalogService();

export default {
  props: ["item", "sale_oos_item"],
  data() {
    return {
      warehouses: [],
    };
  },
  components: {
    PosItemStock,
  },
  methods: {
    ...mapActions(["addItemToCart"]),
    async addNewItemToCart() {
      // allow to add items doesn't exist on stock to shopping cart.
      if (this.sale_oos_item) {
        this.item.quantity = 1;
        this.addItemToCart(this.item);
      } else {
        if (!this.itemExistOnStock) {
          this.$toast.error(`Cet article est en rupture de stock `, {
            position: "top-right",
            timeout: 5000,
          });
          let response = await catalogService.getItems(this.item.id);
          this.warehouses = response.data.warehouses;
          $("#storeModal").modal("show");
          return;
        }

        let match = _.find(this.items, (o) => o.id === this.item.id);
        if (typeof match !== "undefined") {
          if (this.item.stock < match.quantity + 1) {
            this.$toast.error(
              `nous n'avons que ${match.stock} articles pour ce produit.`,
              {
                position: "top-right",
                timeout: 5000,
              }
            );
            return;
          } else {
            this.addItemToCart(this.item);
            this.item.quantity = match.quantity + 1;
            return;
          }
        } else {
          this.item.quantity = 1;
          this.addItemToCart(this.item);
        }
      }
    },
  },
  computed: {
    ...mapState({
      items: (state) => state.cart.order.items,
    }),
    itemExistOnStock() {
      return Number(this.item.stock) > 0;
    },
  },
};
</script>

<style scoped lang="scss">
.no-item {
  opacity: 0.3;
}
.no-item:hover {
  opacity: 0.2;
}
.card {
  padding: 10px;
  cursor: pointer;
  h4 {
    font-weight: 500;
  }
  div {
    font-size: 15px;

    .price {
      font-size: 13px;
    }
  }
}
.card:hover {
  background: #f7f7f7;
  border: 1px solid rgb(2, 117, 216);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
</style>
