<template>
  <div class="container-fluid">
    <base-loading v-if="isLoading" />
    <div v-else>
      <div class="row mt-4">
        <div class="col-md-12">
          <base-breadcrumb :pageName="currentPageName" :storeName="''" />
        </div>
        <!-- Total Due -->
        <app-total-card
          :hasElse="true"
          :total="order.total.incltax"
          title="Montant à payer"
        />
        <!--Total Card-->
        <app-total-card
          customeClass="success"
          :total="totalBuying"
          title="Montant payé"
        />
        <!-- Pay Left Card-->
        <app-total-card customeClass="info" :total="leftToBuy" title="Reste à payer" />
        <!-- Change Card-->
        <app-total-card customeClass="warning" :total="change" title="Rendu" />
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <vee-form class="shadow p-4 mt-4">
            <div class="form-group">
              <label class=""> Montant payer</label>
              <input
                type="number"
                min="0"
                :max="order.total.incltax"
                v-model="enterdAmount"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label class=""> Mode de paiement</label>
              <vee-select
                class="bg-light"
                v-model="payment_method"
                :options="paymentMethods"
                label="title"
                :reduce="(o) => o.id"
              />
            </div>

            <div class="form-group">
              <label class="">Compte d'encaissement</label>
              <vee-select
                class="bg-light"
                v-model="bank"
                :options="banks"
                label="name"
                :reduce="(o) => o.id"
              />
            </div>

            <div class="d-flex justify-content-between">
              <button
                class="btn btn-danger px-3 mr-3"
                type="button"
                @click.prevent="handleCancel"
              >
                Annuler
              </button>
              <button
                class="btn btn-primary ml-3"
                :disabled="order_in_submission"
                type="button"
                @click="addPayment"
              >
                <i class="fa fa-plus"></i>
                Enregistrer
              </button>
            </div>
          </vee-form>
        </div>
        <div class="col-md-6 col-sm-12">
          <h4>Detail de paiement</h4>
          <div class="" v-if="transactions.length">
            <div
              class="d-flex justify-content-between mb-2"
              v-for="(o, i) in apiTransactions"
              :key="o"
            >
              <input
                class="form-control mr-2"
                type="text"
                :value="`${o.amount} ${currency} (${paymentMethodName(
                  o.payment_method
                )})`"
                readonly
              />
              <button @click="removePayment(i)" class="custom-button">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
          <div v-else class="alert alert-warning h-auto">
            <p class="p-3 text-center">aucun paiement n'a encore été ajouté</p>
          </div>
        </div>
      </div>
      <!-- Receipt to print -->
      <div v-show="false">
        <div id="print-frame" style="margin: 0; padding: 0; heigth: 50% !important">
          <app-receipt v-if="showReceipt" :order="selectedReceipt" :showLogo="true" :hideButtons="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import ReceiptService from "@/services/receipt.service";
import AppTotalCard from "@/views/checkout/TotalCard";
import mixins from "@/includes/mixins";
import AppReceipt from "@/views/receipt/PosReceipt";
const receiptService = new ReceiptService();

export default {
  mixins: [mixins],
  components: {
    AppReceipt,
    AppTotalCard,
  },
  data() {
    return {
      isLoading: false,
      apiTransactions: [],
      transactions: [],
      payment_method: null,
      bank: "",
      enterdAmount: "",
      totalBuying: 0,
      storeName: "",
      paymentMethods: [],
      banks: [],
      isRenderd: false,
      currentPageName: "Paiement",
      order_in_submission: false,
      receipt: null,
      currency: null,
      selectedReceipt: null,
    };
  },
  created() {
    this.toggleShowReceipt(false);
    if (!this.order.total.incltax) {
      this.$router.push({ name: "Pos" });
    }
    this.isLoading = true;
    this.isRenderd = false;
    let { settings } = this.getApiData;
    if (Object.keys(settings).length) {
      let store = settings.store;
      this.currency = settings.organization.currency;

      this.storeName = store.title;
      this.paymentMethods = store.payment_methods || [];
      this.banks = store.banks;

      this.bank = store.bank_id;
      this.payment_method = store.payment_method_id;
      this.enterdAmount = this.order.total.incltax;
    }
    this.isLoading = false;
  },
  mounted() {
    if (!this.order.total.incltax) {
      this.$router.push({ name: "Pos" });
    }
  },
  methods: {
    ...mapActions(["clearCart", "updateItemsApiData"]),
    ...mapMutations(["toggleShowReceipt"]),
    async checkout() {
      this.order_in_submission = true;
      let data = {
        id: uuidv4(),
        lines: [],
        discount_type: this.order.discount.type,
        discount_value: this.order.discount.value,
        account: this.order.customer.id,
        date: this.date,
        amount: this.order.total.incltax,
        transactions: this.apiTransactions,
      };

      this.order.items.forEach((item) => {
        data.lines.push({ ...item, type: "item", discount: item.discount * 100 });
      });
      try {
        const response = await receiptService.store([data]);
        const id = response.data[0].receipt_id;
        let receiptResponse = await receiptService.getReceipt(id);
        this.receipt = receiptResponse.data;

        this.updateItemsApiData();
        this.clearCart();
        this.selectedReceipt = structuredClone(receiptResponse.data);
        let lines = (lines = this.selectedReceipt.lines.map((line) => {
          return {
            ...line,
            id: line.linkedid,
            totalLine: this.numberFormat(this.getLineTotal(line), "."),
            discountValue: this.singleLineDiscountValue(line),
            discount_percentage: this.numFormat(line.discount),
          };
        }));

        if (this.change > 0) {
          this.$swal.fire(
            "Attention",
            `Rendu de: ${this.change} ${this.currency}`,
            "warning"
          );
        }
        this.selectedReceipt.lines = lines;
        this.printReceiptProtocol(this.selectedReceipt, "Pos");
      } catch (error) {
        this.$toast.error("échec de paiement!", {
          position: "top-right",
          timeout: 5000,
        });

        this.order_in_submission = false;
      }
    },

    numberFormat(number) {
      return this.$filters.numFormat(number);
    },

    handleCancel() {
      this.$router.push({ name: "Pos" });
    },

    addPayment() {
      const object = {
        bank: this.bank,
        payment_method: this.payment_method,
        amount: this.enterdAmount,
      };

      let object2 = structuredClone(object);

      if (
        this.enterdAmount > this.order.total.incltax &&
        this.apiTransactions.length == 0
      ) {
        object2.amount = this.order.total.incltax;
      } else if (
        this.enterdAmount > this.order.total.incltax &&
        this.apiTransactions.length > 0
      ) {
        object2.amount = this.leftToBuy;
      }

      if (this.enterdAmount > this.leftToBuy) {
        object2.amount = this.leftToBuy;
      }

      this.transactions.push(object);
      this.apiTransactions.push(object2);
    },

    removePayment(index) {
      this.transactions.splice(index, 1);
      this.apiTransactions.splice(index, 1);
    },

    paymentMethodName(id) {
      const match = _.find(this.paymentMethods, (o) => o.id === id);
      return match ? match.title : id;
    },
  },
  computed: {
    ...mapGetters({ getApiData: "getApiData", order: "getOrder" }),
    ...mapState({
      showReceipt: (state) => state.receipt.showReceipt,
    }),
    date() {
      return moment().format("YYYY-MM-DD");
    },

    leftToBuy() {
      let leftToBuy = this.order.total.incltax - this.totalBuying;
      if (leftToBuy > 0) return leftToBuy;
      else return 0;
    },

    change() {
      let total = 0;
      for (let i = 0; i < this.transactions.length; i++) {
        total += this.transactions[i]["amount"];
      }
      let change = total - this.order.total.incltax;
      if (change > 0) {
        return Number(change.toFixed(2));
      }
      return 0;
    },
  },

  watch: {
    apiTransactions: {
      handler(newValue) {
        let total = 0;
        for (let i = 0; i < newValue.length; i++) {
          total += newValue[i]["amount"];
        }
        this.totalBuying = total;
        this.enterdAmount = this.leftToBuy;
        if (total >= this.order.total.incltax) {
          this.checkout();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.none-this-time {
  display: none;
}
.high {
  color: yellow;
}
.custom-button {
  border: 0.1rem solid red;
  border-radius: 0.2rem;
  i {
    color: red;
  }
}
</style>
