<template>
  <div
    :class="{
      flex: variant === 'horizontal',
    }"
  >
    <ul class="list-inline" style="margin-left: 1.4rem;">
      <li class="list-inline-item" v-for="(tab, index) in tabList" :key="index">
        <button
          @click.prevent="updateActiveTab(index + 1)"
          class="btn btn-primary btn-sm"
          :class="{ 'btn-success': activeTab == index + 1 }"
        >
          <i v-if="index== 0" class="fas fa-bars"></i>
          <i  v-if="index== 1" class="fas fa-shopping-cart"></i>
          <i v-if="index== 2" class="fas fa-ban"></i>
          {{ tab }}
        </button>
      </li>
    </ul>
    <template v-for="(tab, index) in tabList">
      <div :key="index" v-if="index + 1 === activeTab">
        <slot :name="`tabPanel-${index + 1}`" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: () => "vertical",
      validator: (value) => ["horizontal", "vertical"].includes(value),
    },
  },
  methods: {
    ...mapMutations(["updateActiveTab"]),
  },
  computed: {
    ...mapState({
      activeTab: (state) => state.cart.activeTab,
    }),
  },
};
</script>

<style>
.flex {
  display: flex;
}
</style>
